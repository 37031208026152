import React, { MutableRefObject, useRef, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import styled from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';
import { FlexContainer, DefaultButton, ResponsiveContainer } from '../styles/styleTemplates';
// @ts-ignore
import ProjectFloorsLogo from '../images/pflogo.svg';
import Fade from 'react-reveal/Fade';
import { getWebsiteUrlString } from '../lib/util/location';

export default (props) => {
    const checkbox = useRef() as MutableRefObject<HTMLInputElement>;

    const [email, setEmail] = useState('');
    const [valid, setValid] = useState(false);
    const [isSubmitted, setIsSumbitted] = useState(false);

    const handleSubmit = async (subscribe) => {
        if (valid) {
            await subscribe({
                EMAIL: email,
            });
            setIsSumbitted(true);
        }
    };

    const url = 'https://project-floors.us11.list-manage.com/subscribe/post?u=3b97b0ef2f42dc30d41d5e747&id=839fffd01f';

    return (
        <Fade bottom>
            <StyledContainer fluid={true}>
                <StyledRow>
                    <Col md={4} sm={12}>
                        <Card flexDirection="column" alignItems="center">
                            <FlexContainer flexDirection="column" alignItems="start">
                                <FlexContainer flexDirection="column">
                                    <Heading>Checkliste</Heading>
                                    <SubHeading>Download</SubHeading>
                                </FlexContainer>
                                <p>
                                    Haben Sie an alles gedacht? Wir haben für Sie eine Checkliste für den Besuch bei Ihrem Fachhändler zusammengestellt. So
                                    können Sie sicher sein, dass Sie bestens vorbereitet sind und an alles denken. Wir wünschen Ihnen gute Gespräche und viel
                                    Spaß bei der Auswahl Ihres Designbodens.
                                </p>
                                <DefaultButton>
                                    <a href="/Checkliste.pdf" target="_blank">
                                        download
                                    </a>
                                </DefaultButton>
                            </FlexContainer>
                        </Card>
                    </Col>
                    <Col md={4} sm={12}>
                        <Card flexDirection="column" alignItems="center">
                            <FlexContainer flexDirection="column" alignItems="start">
                                <FlexContainer flexDirection="column">
                                    <Heading>Newsletter</Heading>
                                    <SubHeading>Anmeldung</SubHeading>
                                </FlexContainer>
                                {isSubmitted && (
                                    <React.Fragment>
                                        <ThankAdvice>Vielen Dank für ihre Anmeldung.</ThankAdvice>
                                        <p>Um Ihre Anmeldung abzuschließen, aktivieren Sie bitte den Link in der soeben an Sie versendeten E-Mail.</p>
                                    </React.Fragment>
                                )}
                                {!isSubmitted && (
                                    <React.Fragment>
                                        <p>
                                            Sie wollen mehr über die neusten Trends rund um den Designboden erfahren? Dann melden Sie sich zu unserem Newsletter
                                            an und bleiben Sie auf dem Laufenden:{' '}
                                        </p>
                                        <EmailInput placeholder="E-Mail" onChange={(e) => setEmail(e.target.value)} />
                                        <Datenschutz>
                                            <Checkbox type="checkbox" id="checkbox" ref={checkbox} onChange={(e) => setValid(e.target.checked)} />
                                            <label htmlFor="checkbox">Mit dem Absenden dieses Formulars akzeptieren Sie unsere Datenschutzbedingungen.</label>
                                        </Datenschutz>
                                    </React.Fragment>
                                )}
                                <div
                                    style={{
                                        display: !isSubmitted ? 'block' : 'none',
                                    }}>
                                    <MailchimpSubscribe
                                        url={url}
                                        render={({ subscribe }) => (
                                            <DefaultButton onClick={() => handleSubmit(subscribe)}>
                                                <span>anmelden</span>
                                            </DefaultButton>
                                        )}
                                    />
                                </div>
                            </FlexContainer>
                        </Card>
                    </Col>
                    <Col md={4} sm={12}>
                        <Card flexDirection="column" alignItems="center">
                            <Wrapper flexDirection="column">
                                <Heading>Fragen?</Heading>
                                <SubHeading>Antworten</SubHeading>
                                <FlexContainer style={{ position: 'relative' }}>
                                    <AddressPrefix flexDirection="column" justifyContent="flex-end">
                                        <ListItemPrefix>M</ListItemPrefix>
                                        <ListItemPrefix>T</ListItemPrefix>
                                    </AddressPrefix>
                                    <ResultAddress>
                                        <li>{getWebsiteUrlString(props.location)}</li>
                                        <li>PROJECT FLOORS GmbH</li>
                                        <li>Max-Ernst-Straße 4</li>
                                        <li>D-50354 Hürth</li>
                                        <li>
                                            <a href="mailto:info@bodengutgemacht.de">info@bodengutgemacht.de</a>
                                        </li>
                                        <li>
                                            <a href="tel:+490223396870">+49 (0) 2233-96870</a>
                                        </li>
                                    </ResultAddress>
                                </FlexContainer>
                                <FooterLogoWrapper flexDirection="column">
                                    <span>Powered by</span>
                                    <a href="https://www.project-floors.com/" target="_blank">
                                        <Logo src={ProjectFloorsLogo} alt="logo" />
                                    </a>
                                </FooterLogoWrapper>
                            </Wrapper>
                        </Card>
                    </Col>
                </StyledRow>
            </StyledContainer>
        </Fade>
    );
};

const ThankAdvice = styled.p`
    font-family: ${(props) => props.theme.fonts.roman};
    color: ${(props) => props.theme.colors.burntSienna} !important;
`;

const AddressPrefix = styled(FlexContainer)`
    position: absolute;
    bottom: 0;
    left: -20px;
    @media screen and (max-width: 375px) {
        left: -15px;
        font-size: 10px;
    }
`;

const Wrapper = styled(FlexContainer)`
    width: 18.75rem;
    @media screen and (max-width: 375px) {
        width: auto;
    }
    ${media.md`
        width: auto;
    `}
`;

const StyledContainer = styled(ResponsiveContainer)`
    margin-top: 6.938rem;
    padding: 0 1.375rem;
    ${media.lg`
        padding: 0 4.375rem;
    `}
    @media screen and (max-width: 375px) {
        margin-top: 12.938rem;
    }
`;

const StyledRow = styled(Row)`
    margin: 0;
    min-height: 35.625rem;
    background-color: ${(props) => props.theme.colors.fadedOrange};
`;

const Card = styled(FlexContainer)`
    margin: 2rem 0 4rem 0;
    text-align: left;
    p {
        max-width: 19.063rem;
        color: ${(props) => props.theme.colors.white};
        font-size: 0.875rem;
        line-height: 1.71;
        letter-spacing: 0.125rem;
        @media screen and (max-width: 375px) {
            font-size: 10px;
        }
    }
    @media screen and (max-width: 375px) {
        margin: 2rem 1.5rem;
        align-items: flex-start !important;
    }
    ${media.md`
        margin-top: 4.25rem;
    `}
`;

const Heading = styled.h2`
    color: ${(props) => props.theme.colors.white};
    margin: 0;
`;

const SubHeading = styled.h2`
    color: ${(props) => props.theme.colors.burntSienna};
    margin: 0 0 0.5rem 0;
`;

const EmailInput = styled.input`
    height: 2.063rem;
    max-width: 19.063rem;
    outline: none;
    background-color: transparent;
    border-color: none;
    border-style: outline;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    font-size: 0.875rem;
    line-height: 1.71;
    letter-spacing: 0.125rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.white};
    width: 100%;
    color: ${(props) => props.theme.colors.white};
    &::placeholder {
        color: ${(props) => props.theme.colors.burntSienna};
    }
`;

const Datenschutz = styled(FlexContainer)`
    margin-top: 1.5rem;
    margin-bottom: 1.563rem;
    label {
        cursor: pointer;
        font-size: 0.625rem;
        line-height: 1.2;
        letter-spacing: 0.063rem;
        max-width: 17.25rem;
        margin-left: 0.75rem;
        color: ${(props) => props.theme.colors.white};
    }
`;

const Checkbox = styled.input`
    height: 1.25rem;
    width: 1.25rem;
    outline: none;
    margin: 0;
    border-radius: 0px;
    background-color: transparent;
    border: solid 1px ${(props) => props.theme.colors.impressionText};
`;

const ResultAddress = styled.ul`
    list-style: none;
    margin: 0.875rem 0 0 0;
    padding: 0;
    li {
        color: ${(props) => props.theme.colors.white};
        font-size: 0.875rem;
        line-height: 1.71;
        letter-spacing: 0.156rem;
        span {
            margin-left: 0.5rem;
        }
        @media screen and (max-width: 375px) {
            font-size: 10px;
        }
    }
    a {
        color: ${(props) => props.theme.colors.white};
        text-decoration: none;
    }
`;

const ListItemPrefix = styled.span`
    text-align: left;
    line-height: 1.56;
    letter-spacing: 0.156rem;
    color: #cd5e1e;
`;

const FooterLogoWrapper = styled(FlexContainer)`
    margin-top: 2.75rem;
    span {
        color: ${(props) => props.theme.colors.burntSienna};
        font-size: 0.875rem;
        line-height: 1.71;
        letter-spacing: 0.156rem;
    }
`;

const Logo = styled.img`
    height: 3.438rem;
    width: 10.125rem;
`;
