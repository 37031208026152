import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';
import { MobileNavProps, NavLinkProps } from '../Interface/Navigation';
import { Link } from 'gatsby';
import { NAVLINK_SUCHE, NAVLINK_TIPPS, NAVLINK_IMPRESSIONEN, NAVLINK_KONTAKT, NAVLINK_FLOORS, GERMANY, AUSTRIA, SWITZERLAND } from '../constants/constants';
import { FlexContainer, ResponsiveContainer } from '../styles/styleTemplates';
// @ts-ignore
import LogoDE from '../images/v-2.svg';
// @ts-ignore
import LogoAT from '../images/logo_at.svg';
// @ts-ignore
import LogoCH from '../images/logo_ch.svg';
// @ts-ignore
import Pflogo from '../images/pflogo.svg';
// @ts-ignore
import Facebook from '../images/icons/facebook.svg';
// @ts-ignore
import Pinterest from '../images/icons/pinterest.svg';
// @ts-ignore
import Instagram from '../images/icons/instagram.svg';
// @ts-ignore
import Twitter from '../images/icons/twitter.svg';

export default (props) => {
    const [activeLink, setActiveLink] = useState(NAVLINK_SUCHE);
    const [navOpen, setNavOpen] = useState(false);

    const mobileNavLinks = [NAVLINK_IMPRESSIONEN, NAVLINK_TIPPS, NAVLINK_KONTAKT, NAVLINK_FLOORS];
    const navLinks = [NAVLINK_SUCHE, NAVLINK_IMPRESSIONEN, NAVLINK_TIPPS];

    return (
        <React.Fragment>
            <Background />
            <RelativeContainer fluid={true}>
                <MobileNav navOpen={navOpen} onClick={() => setNavOpen(!navOpen)}>
                    <span />
                    <span />
                    <span />
                </MobileNav>
                <LogoWrapper justifyContent="center" alignItems="center">
                    <Link to="/">
                        {props.location !== null && (
                            <>
                                {props.location === GERMANY && <img className={props.location} src={LogoDE} alt="Bodengutgemacht Logo" />}
                                {props.location === AUSTRIA && <img className={props.location} src={LogoAT} alt="Bodengutgemacht Logo" />}
                                {props.location === SWITZERLAND && <img className={props.location} src={LogoCH} alt="Bodengutgemacht Logo" />}
                            </>
                        )}
                    </Link>
                </LogoWrapper>
                <CustomRow alignItems="center" justifyContent="between">
                    <Col noGutter={true} col={7}>
                        <FlexContainer alignItems="center" justifyContent="space-between">
                            <div>
                                {navLinks.map((link, idx) => (
                                    <NavLink key={idx} to={`/#${link}`} text={link} setActive={setActiveLink} active={activeLink === link} />
                                ))}
                            </div>
                        </FlexContainer>
                    </Col>
                    <Col noGutter={true} col={5}>
                        <a href="https://www.project-floors.com/" target="_blank">
                            <ProjectFloorsLogo src={Pflogo} alt="Project Floors Logo" />
                        </a>
                    </Col>
                </CustomRow>
            </RelativeContainer>
            {navOpen && (
                <MobileNavContent flexDirection="column">
                    <Nav>
                        <ul>
                            {mobileNavLinks.map((link, idx) => (
                                <li key={idx}>
                                    {link === NAVLINK_FLOORS && (
                                        <FloorsNavLink href="https://www.project-floors.com/" target="_blank">
                                            {link}
                                        </FloorsNavLink>
                                    )}
                                    {link !== NAVLINK_FLOORS && (
                                        <MobileNavlink to={`/#${link}`} onClick={() => setNavOpen(false)}>
                                            {link}
                                        </MobileNavlink>
                                    )}
                                </li>
                            ))}
                        </ul>
                        <ul>
                            <div>
                                <li>
                                    <a href="https://www.project-floors.com/footer/impressum/" target="_blank" onClick={() => setNavOpen(false)}>
                                        Impressum
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.project-floors.com/datenschutz/" target="_blank" onClick={() => setNavOpen(false)}>
                                        Datenschutz
                                    </a>
                                </li>
                                <li>
                                    <img src={Pinterest} alt="Pinterest-icon" />
                                    <a href="https://www.pinterest.de/PROJECT_FLOORS/_created/">Pinterest</a>
                                </li>
                                <li>
                                    <img src={Instagram} alt="Instagram-icon" />
                                    <a href="https://www.instagram.com/project_floors/?hl=de">Instagram</a>
                                </li>
                                <li>
                                    <img src={Facebook} alt="Facebook-icon" />
                                    <a href="https://facebook.com/PROJECT.FLOORS">Facebook</a>
                                </li>
                                <li>
                                    <img src={Twitter} alt="Twitter-icon" />
                                    <a href="https://twitter.com/project_floors?lang=de">Twitter</a>
                                </li>
                            </div>
                        </ul>
                    </Nav>
                </MobileNavContent>
            )}
        </React.Fragment>
    );
};

interface StyleProps {
    active: string;
    linktype: string;
}

const Nav = styled.nav`
    ul {
        padding-left: 5rem;
        list-style: none;
        margin-top: 1.75rem;
        ${(props) => ({ ...props.theme.flexAlignEnd })};
        justify-content: end;
        li {
            position: relative;
            img {
                position: absolute;
                left: -2.7rem;
                bottom: -0.188rem;
            }
            ${(props) => ({ ...props.theme.flexStart })};
            margin-top: 1.875rem;
            a {
                font-size: 1rem;
                color: ${(props) => props.theme.colors.black};
                letter-spacing: 0.084rem;
            }
        }
    }
    ul:first-of-type {
        display: block;
        margin-top: 4.75rem;
        li {
            margin: 1rem 0;
        }
    }
`;

const MobileNavContent = styled(FlexContainer)`
    position: fixed;
    height: 100%;
    width: 90%;
    background-color: ${(props) => props.theme.colors.veryLightPink};
    top: 0;
    left: 0;
    overflow: scroll;
    z-index: 5;
`;

const MobileNav = styled.div<MobileNavProps>`
    display: block;
    position: ${(props) => (props.navOpen ? 'fixed' : 'absolute')};
    height: 1.063rem;
    width: 1.313rem;
    top: 1.938rem;
    left: 1.938rem;
    z-index: 15;
    span {
        z-index: 10;
        display: block;
        position: relative;
        height: 0.125rem;
        width: 100%;
        background-color: ${(props) => props.theme.colors.black};
        transition: all 0.2s;
        &:nth-child(1) {
            transform: ${(props) => (props.navOpen ? 'rotate(45deg)' : 'none')} translate(0.063rem, 0.125rem);
            width: 102%;
        }
        &:nth-child(2) {
            margin: 0.3rem 0;
            display: ${(props) => (props.navOpen ? 'none' : 'block')};
        }
        &:nth-child(3) {
            transform: ${(props) => (props.navOpen ? 'rotate(-45deg)' : 'none')};
        }
    }
    ${media.lg`
        display: none;
    `};
`;

const RelativeContainer = styled(ResponsiveContainer)`
    position: relative;
    height: 6.813rem;
    ${media.lg`
        height: unset;
    `}
`;

const LogoWrapper = styled(FlexContainer)`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: ${(props) => props.theme.colors.backgroundLight};
    a {
        z-index: 4;
    }
    ${media.lg`
        background-color: transparent;
    `}
`;

const ProjectFloorsLogo = styled.img`
    height: 2.125rem;
    width: 6.25rem;
    margin-left: auto;
    position: relative;
    display: block;
    filter: invert(1);
`;

const CustomRow = styled(Row)`
    height: 6.75rem;
    display: none;
    ${media.lg`
        display: flex;
    `}
`;

const MobileNavlink = styled(Link).attrs((props) => ({ style: { ...props.theme.navLinks } }))`
    color: ${(props) => props.theme.linkColors.default};
    text-transform: capitalize;
`;

const FloorsNavLink = styled(MobileNavlink)``;

const NavLinkStyle = styled(Link).attrs((props) => ({ style: { ...props.theme.navLinks } }))<StyleProps>`
    color: ${(props) => props.theme.linkColors.default};
    text-transform: uppercase;
    text-decoration: ${(props) => (props.active === 'true' ? 'line-through' : 'none')};
    margin-right: ${(props) => (props.linktype === NAVLINK_SUCHE ? '1.563rem' : '0.875rem')};
    &:hover {
        text-decoration: line-through;
    }
`;

const Background = styled.div`
    width: 100%;
    height: 35.563rem;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.backgroundLight};
    display: none;
    ${media.lg`
        display: block;
    `}
`;

const NavLink = ({ to, text, active, setActive }: NavLinkProps) => (
    <NavLinkStyle to={to} active={`${active}`} linktype={text} onClick={() => setActive(text)}>
        {text}
    </NavLinkStyle>
);
