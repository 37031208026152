import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';
import { FlexContainer, ResponsiveContainer } from '../styles/styleTemplates';
// @ts-ignore
import Facebook from '../images/icons/facebook.svg';
// @ts-ignore
import Pinterest from '../images/icons/pinterest.svg';
// @ts-ignore
import Instagram from '../images/icons/instagram.svg';
// @ts-ignore
import Twitter from '../images/icons/twitter.svg';
// @ts-ignore
import Linked from '../images/icons/linked.svg';

export default ({}) => {
    return (
        <React.Fragment>
            <Background />
            <FooterWrapper fluid={true}>
                <StyledRow justifyContent="between">
                    <Col col={6} noGutter={true}>
                        <FooterLinks>
                            <a href="https://www.project-floors.com/footer/impressum/" target="_blank">
                                Impressum
                            </a>
                            <a href="https://www.project-floors.com/datenschutz/" target="_blank">
                                Datenschutz
                            </a>
                        </FooterLinks>
                    </Col>
                    <Col col={6} noGutter={true}>
                        <FlexContainer justifyContent="flex-end" alignItems="center" height="100%">
                            <FooterLinkWithIcon>
                                <FlexContainer alignItems="center">
                                    <span>
                                        <img src={Pinterest} alt="Pinterest-icon" />
                                    </span>
                                    <a href="https://www.pinterest.de/PROJECT_FLOORS/_created/" target="_blank">
                                        Pinterest
                                    </a>
                                </FlexContainer>
                                <FlexContainer alignItems="center">
                                    <span>
                                        <img src={Facebook} alt="Facebook-icon" />
                                    </span>
                                    <a href="https://facebook.com/PROJECT.FLOORS" target="_blank">
                                        Facebook
                                    </a>
                                </FlexContainer>
                                <FlexContainer alignItems="center">
                                    <span>
                                        <img src={Linked} alt="LinkedIn-icon" style={{ height: 30 }} />
                                    </span>
                                    <a href="https://www.linkedin.com/company/project-floors-gmbh/" target="_blank">
                                        LinkedIn
                                    </a>
                                </FlexContainer>
                            </FooterLinkWithIcon>
                            <RightIconRow>
                                <FlexContainer alignItems="center">
                                    <span>
                                        <img src={Instagram} alt="Instagram-icon" />
                                    </span>
                                    <a href="https://www.instagram.com/project_floors/?hl=de" target="_blank">
                                        Instagram
                                    </a>
                                </FlexContainer>
                                <FlexContainer alignItems="center">
                                    <span>
                                        <img src={Twitter} alt="Twitter-icon" />
                                    </span>
                                    <a href="https://twitter.com/project_floors?lang=de" target="_blank">
                                        Twitter
                                    </a>
                                </FlexContainer>
                            </RightIconRow>
                        </FlexContainer>
                    </Col>
                </StyledRow>
            </FooterWrapper>
        </React.Fragment>
    );
};

const FooterWrapper = styled(ResponsiveContainer)`
    height: 8.375rem;
    position: relative;
`;

const StyledRow = styled(Row)`
    height: 100%;
    margin: 0;
`;

const Background = styled.div`
    position: absolute;
    height: 34.75rem;
    width: 100%;
    z-index: -1;
    bottom: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.veryLightPink};
`;

const FooterLinks = styled(FlexContainer)`
    height: 100%;
    align-items: center;
    a {
        text-decoration: underline;
        font-size: 0.875rem;
        color: ${(props) => props.theme.colors.black};
    }
    a:nth-child(2) {
        margin-left: 0.313rem;
        ${media.sm`
            margin-left: 1.25rem;
        `}
    }
    @media screen and (max-width: 375px) {
        flex-direction: column;
        align-items: start;
        justify-content: center;
        a:nth-child(2) {
            margin-left: 0;
            margin-top: 1rem;
        }
    }
`;

const FooterLinkWithIcon = styled(FlexContainer)`
    flex-direction: column;
    ${media.md`
        flex-direction: row;
    `}
    a {
        text-decoration: underline;
        color: ${(props) => props.theme.colors.black};
        margin-left: 5px;
        font-size: 0.875rem;
        ${media.md`
            margin: 0 0.938rem;
        `}
    }
`;

const RightIconRow = styled(FooterLinkWithIcon)`
    margin-left: 23px;
    ${media.md`
        margin-left: 0;
    `}
    @media screen and (max-width: 767px) {
        align-self: start;
        margin-top: 0.7rem;
    }
`;
