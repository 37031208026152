import styled, { ThemedStyledProps } from 'styled-components';
import { Container, media } from 'styled-bootstrap-grid';

interface FlexContainerProps extends ThemedStyledProps<any, any> {
    style: {
        height?: string;
        width?: string;
        alignItems?: string;
        justifyContent?: string;
        flexDirection?: string;
    }
}

export const FlexContainer = styled.div.attrs((props: FlexContainerProps) => ({
    style: {
        height: props.height ? props.height : '',
        width: props.width ? props.width : '',
        display: 'flex',
        alignItems: props.alignItems ? props.alignItems : '',
        justifyContent: props.justifyContent ? props.justifyContent : '',
        flexDirection: props.flexDirection ? props.flexDirection : '',
    },
}))<FlexContainerProps>``;

export const ResponsiveContainer = styled(Container)`
    padding: 0 1.438rem;
    ${media.lg`
        padding: 0 4.375rem;
    `}
`

export const DefaultButton = styled.button`
    width: 8.438rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.lightGrey};
    border: none;
    border-radius: 0;
    cursor: pointer;
    background-position: center;
    transition: background 0.5s;
    &:focus {
        outline: none;
    }
    &:hover {
          background: ${(props) => props.theme.colors.lightGrey} radial-gradient(circle, transparent 1%, ${(props) => props.theme.colors.lightGrey} 1%) center/15000%;
    }
    &:active {
        background-color: ${(props) => props.theme.colors.pale};
        background-size: 100%;
        transition: background 0s;
    }
    a, span {
        color: #cd5e1e;
        text-transform: uppercase;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: 0.078rem;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
`;

export const SubSiteWrapper = styled(Container)`
    h2,
    h1 {
        color: ${(props) => props.theme.colors.black};
        margin-bottom: 1rem;
    }
    p {
        max-width: 53.688rem;
        hyphens: auto;
        font-size: ${(props) => props.theme.fontSizes.sm};
        line-height: 1.67;
        letter-spacing: 0.094rem;
        color: ${(props) => props.theme.colors.darkGrey};
    }
    a {
        color: ${(props) => props.theme.colors.black};
    }
`;