import { AUSTRIA, GERMANY, SWITZERLAND } from '../../constants/constants';

export const getLocationCode = (location): string | null => {
    if (location?.href?.includes('.de')) return GERMANY;
    if (location?.href?.includes('.at')) return AUSTRIA;
    if (location?.href?.includes('.ch')) return SWITZERLAND;

    return null;
}

export const getWebsiteUrlString = (location): string => {
    if (location === GERMANY) return 'bodengutgemacht.de';
    if (location === AUSTRIA) return 'bodengutgemacht.at';
    if (location === SWITZERLAND) return 'bodengutgemacht.ch';

    return 'bodengutgemacht.de';
};
